import { defineStore } from "pinia";
import { reactive, ref, computed } from "vue";

export const useLoadingStore = defineStore("loading", () => {
  const isLoading = ref(false);

  const toggleLoading = () => {
    isLoading.value = !isLoading.value;
  };

  const setLoading = (value) => {
    isLoading.value = value;
  };

  return { isLoading, toggleLoading, setLoading };
});
