<template>
  <div class="form-dropzone">
    <label>{{ label }}</label>
    <Dropzone
      ref-key="dropzoneMultipleRef"
      :options="{
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        // maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        autoProcessQueue: false,
        init() {
          this.on('addedfile', function (file) {
            console.log('Added file: ', file);
            addFile(file);
          });

          this.on('removedfile', function (file) {
            console.log('Removed file: ', file);
            removeFile(file);
          });
        },
      }"
      class="dropzone"
    >
      <div class="text-lg font-medium">Drop files here or click to upload.</div>
      <div class="text-gray-600">
        This is just a demo dropzone. Selected files are
        <span class="font-medium">not</span> actually uploaded.
      </div>
    </Dropzone>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
    required: true,
  },
});

const emits = defineEmits(["files"]);

const files = ref([]);

const addFile = (file) => {
  console.log(file);
  files.value.push(file);
};

const removeFile = (file) => {
  console.log(file);
  files.value = files.value.filter((item) => item !== file);
};

watch(
  files,
  (value) => {
    console.log(value);

    emits("files", value);
  },
  { deep: true }
);
</script>
