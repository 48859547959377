import Api from "@/utils/api.js";

class purchaseOrderItemServices {
  async get(params) {
    const res = await Api.doGet(`purchase-order-item`, {
      params,
    });
    console.log("SERVICES PRODUCT ITEM ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`purchase-order-item/${id}`);
    console.log("SERVICES PRODUCT ITEM BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`purchase-order-item`, params);
    console.log("SERVICES PRODUCT ITEM CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`purchase-order-item/${id}`, params);
    console.log("SERVICES PRODUCT ITEM UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`purchase-order-item/${id}`);
    console.log("SERVICES PRODUCT ITEM DELETE", res);
    return res;
  }

  async exportExcel(params) {
    const res = await Api.doPostBlob(`purchase-order-item/export`, params);
    console.log("SERVICES PRODUCT ITEM EXPORT", res);
    return res;
  }

  async getUuid(params) {
    const res = await Api.doGet(`purchase-order-item/uuid`, {
      params,
    });
    console.log("SERVICES PRODUCT ITEM ALL}", res);
    return res;
  }
}

export default new purchaseOrderItemServices();
