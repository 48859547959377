import { createRouter, createWebHistory } from "vue-router";
import SideMenu from "../layouts/side-menu/Main.vue";
import SimpleMenu from "../layouts/simple-menu/Main.vue";
import TopMenu from "../layouts/top-menu/Main.vue";
import Page1 from "../views/page-1/Main.vue";
import Page2 from "../views/page-2/Main.vue";
import Login from "../views/login/Main.vue";
import Testing from "@/views/Testing.vue";
import Cookies from "js-cookie";

const routes = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "/",
        name: "side-menu-home",
        component: () => import("../views/home/Main.vue"),
      },
      {
        path: "/page-1",
        name: "side-menu-page-1",
        component: Page1,
      },
      {
        path: "page-2",
        name: "side-menu-page-2",
        component: Page2,
      },
      {
        path: "session",
        name: "side-menu-session",
        component: () => import("../views/session/Main.vue"),
      },
      {
        path: "purchase-order",
        name: "side-menu-purchase-order",
        component: () => import("../views/purchase-order/Main.vue"),
      },
      {
        path: "purchase-order-invoice/:id",
        name: "side-menu-purchase-order-invoice",
        component: () => import("../views/purchase-order/Invoice.vue"),
      },
      // Master
      {
        path: "branch",
        name: "side-menu-branch",
        component: () => import("../views/master/branch/Main.vue"),
      },
      {
        path: "customer",
        name: "side-menu-customer",
        component: () => import("../views/master/customer/Main.vue"),
      },
      {
        path: "employee",
        name: "side-menu-employee",
        component: () => import("../views/master/employee/Main.vue"),
      },
      {
        path: "product",
        name: "side-menu-product",
        component: () => import("../views/master/product/Main.vue"),
      },
      {
        path: "promo",
        name: "side-menu-promo",
        component: () => import("../views/master/promo/Main.vue"),
      },
      {
        path: "role",
        name: "side-menu-role",
        component: () => import("../views/master/role/Main.vue"),
      },
      {
        path: "user",
        name: "side-menu-user",
        component: () => import("../views/master/user/Main.vue"),
      },
      {
        path: "category",
        name: "side-menu-category",
        component: () => import("../views/master/category/Main.vue"),
      },
      {
        path: "specialist",
        name: "side-menu-specialist",
        component: () => import("../views/master/specialist/Main.vue"),
      },
      // End Master
    ],
  },
  {
    path: "/simple-menu",
    component: SimpleMenu,
    children: [
      {
        path: "/",
        name: "simple-menu-home",
        component: () => import("../views/home/Main.vue"),
      },
      {
        path: "page-1",
        name: "simple-menu-page-1",
        component: Page1,
      },
      {
        path: "page-2",
        name: "simple-menu-page-2",
        component: Page2,
      },
      {
        path: "branch",
        name: "simple-menu-branch",
        component: () => import("../views/master/branch/Main.vue"),
      },
      {
        path: "customer",
        name: "simple-menu-customer",
        component: () => import("../views/master/customer/Main.vue"),
      },
      {
        path: "employee",
        name: "simple-menu-employee",
        component: () => import("../views/master/employee/Main.vue"),
      },
      {
        path: "promo",
        name: "simple-menu-promo",
        component: () => import("../views/master/promo/Main.vue"),
      },
      {
        path: "user",
        name: "simple-menu-user",
        component: () => import("../views/master/user/Main.vue"),
      },
      {
        path: "category",
        name: "simple-menu-category",
        component: () => import("../views/master/category/Main.vue"),
      },
    ],
  },
  {
    path: "/top-menu",
    component: TopMenu,
    children: [
      {
        path: "/",
        name: "top-menu-home",
        component: () => import("../views/home/Main.vue"),
      },
      {
        path: "page-1",
        name: "top-menu-page-1",
        component: Page1,
      },
      {
        path: "page-2",
        name: "top-menu-page-2",
        component: Page2,
      },
      {
        path: "branch",
        name: "top-menu-branch",
        component: () => import("../views/master/branch/Main.vue"),
      },
      {
        path: "customer",
        name: "top-menu-customer",
        component: () => import("../views/master/customer/Main.vue"),
      },
      {
        path: "employee",
        name: "top-menu-employee",
        component: () => import("../views/master/employee/Main.vue"),
      },
      {
        path: "promo",
        name: "top-menu-promo",
        component: () => import("../views/master/promo/Main.vue"),
      },
      {
        path: "user",
        name: "top-menu-user",
        component: () => import("../views/master/user/Main.vue"),
      },
      {
        path: "category",
        name: "top-menu-category",
        component: () => import("../views/master/category/Main.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/register/Main.vue"),
  },
  {
    path: "/testing",
    name: "testing",
    component: Testing,
  },
  // error page
  {
    path: "/:pathMatch(.*)*",
    name: "error-404",
    component: () => import("../views/error-page/Main.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuth = Cookies.get("auth.token") ? true : false;

  if (to.name === "login" && isAuth) next({ name: "side-menu-home" });
  else if (to.name !== "login" && !isAuth) next({ name: "login" });
  else next();
});

export default router;
