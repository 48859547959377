import Api from "@/utils/api.js";

class customerServices {
  async get(params) {
    const res = await Api.doGet(`customer`, {
      params,
    });
    console.log("SERVICES CUSTOMER ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`customer/${id}`);
    console.log("SERVICES CUSTOMER BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`customer`, params);
    console.log("SERVICES CUSTOMER CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`customer/${id}`, params);
    console.log("SERVICES CUSTOMER UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`customer/${id}`);
    console.log("SERVICES CUSTOMER DELETE", res);
    return res;
  }
}

export default new customerServices();
