import axios from "axios";
import Cookies from "js-cookie";
import { useLoadingStore } from "@/stores/loading.js";
import { useAuthStore } from "@/stores/auth.js";
import { showSuccessPopup, showFailedPopup } from "./popup";
import { useRoute, useRouter } from "vue-router";

const host = import.meta.env.VITE_BASE_URL || "";
console.log(host);
const version = "v1";
const baseUrl = `${host}/api/${version}/`;
// const baseUrl = `${host}/api/`;

const app = axios.create({
  baseURL: baseUrl,
  timeout: 60000, // 60s
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

app.interceptors.request.use(async (config) => {
  await new Promise((resolve) => setTimeout(resolve, 100));

  const authToken = await Cookies.get("auth.token");

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  const loading = useLoadingStore();
  loading.toggleLoading();

  return config;
});

app.interceptors.response.use(
  async (res) => {
    const loading = useLoadingStore();
    loading.setLoading(false);

    return res;
  },
  async (error) => {
    const loading = useLoadingStore();
    loading.setLoading(false);

    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // const res = await axios.post(`${host}/api/auth/request-jwt`, {
      //   token: Cookies.get("auth.refreshToken"),
      // });

      const loggedOn = await Cookies.get("auth.loggedOn");

      if (loggedOn) {
        const authStore = useAuthStore();
        const res = await authStore.logout();
        window.location.href = "/login";
      }

      // if (res.status === 200) {
      //   Cookies.set("auth.token", res.data.token);
      //   // Cookies.set("auth.refreshToken", res.data.refreshToken);
      //   axios.defaults.headers.common["Authorization"] =
      //     "Bearer " + res.data.token;
      //   return axios(originalRequest);
      // }
    } else {
      console.log("ERROR", error);
      showFailedPopup("Failed", error.response?.data?.message);
    }

    return Promise.reject(error);
  }
);

class Api {
  host = baseUrl;

  async doGet(url, params) {
    return await app
      .get(url, params)
      .then((res) => res)
      .catch((err) => err);
  }

  async doPost(url, params) {
    return await app
      .post(url, params)
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  }

  async doPut(url, params) {
    return await app
      .put(url, params)
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  }

  async doPatch(url, params) {
    return await app
      .patch(url, params)
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  }

  async doDelete(url) {
    return await app
      .delete(url)
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  }

  async doGetBlob(url, params) {
    return await app
      .get(url, params, {
        responseType: "blob",
      })
      .then((res) => res)
      .catch((err) => err);
  }

  async doPostBlob(url, params) {
    return await app
      .post(url, params, { responseType: "blob" })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  }

  async doPostMultipart(url, params) {
    return await app
      .post(url, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  }
}

export default new Api();
