import { defineStore } from "pinia";
import { reactive, ref, computed } from "vue";
import services from "@/services/auth.js";
import Cookies from "js-cookie";
import { showFailedPopup } from "../utils/popup";
import { useLoadingStore } from "./loading";

export const useAuthStore = defineStore("auth", () => {
  const isAuth = reactive({
    token: Cookies.get("auth.token") ? Cookies.get("auth.token") : "",
    refreshToken: "",
    isLogged: Cookies.get("auth.token") ? true : false,
    user: Cookies.get("auth.user")
      ? JSON.parse(Cookies.get("auth.user") || "")
      : {},
  });

  const setState = (key, value) => {
    isAuth[key] = value;
  };

  const fetchAuth = async ({ params }) => {
    try {
      const res = await services.login({ params });
      console.log("AUTH STORE", res);

      isAuth.isLogged = true;
      isAuth.token = res?.data?.data?.token || "";
      Cookies.set("auth.loggedOn", true, { expires: 1 });
      Cookies.set("auth.token", res?.data?.data?.token, { expires: 1 });

      if (res?.data?.data?.token) {
        setTimeout(() => {
          fetchMe();
        }, 1000);
      }

      return res;
    } catch (error) {
      console.error(error);

      showFailedPopup("Failed", "Login failed");

      const loading = useLoadingStore();
      loading.setLoading(false);
    }
  };

  const fetchMe = async () => {
    try {
      const res = await services.me();
      console.log("ME STORE", res);

      Cookies.set("auth.user", JSON.stringify(res?.data?.data), { expires: 1 });
      isAuth.user = res?.data?.data || {};

      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const logout = () => {
    Cookies.remove("auth.token");
    Cookies.remove("auth.user");
    Cookies.remove("auth.loggedOn");
    isAuth.isLogged = false;
    isAuth.token = "";
    isAuth.user = {};
  };

  return { setState, fetchAuth, fetchMe, isAuth, logout };
});
