<template>
  <router-view />

  <ModalRegister />

  <!-- Notification -->
  <Notification />

  <Notivue class="Notivue__notification" v-slot="item">
    <Notifications :item="item" />
  </Notivue>

  <teleport to="body">
    <div
      v-if="isLoading"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <Loading />
    </div>
  </teleport>

  <!-- Version -->
  <div>
    <div class="text-center text-white">
      © 2023 First Physio. {{ version }} | Powered By : RajaCoding
    </div>
  </div>
</template>
<script setup>
import ModalRegister from "@/layouts/modal/Main.vue";
import Loading from "@/components/base/loading/Main.vue";
import Notification from "@/components/utils/notification/Main.vue";
import { Notivue, Notifications } from "notivue";

import { useLoadingStore } from "@/stores/loading";

import { computed } from "vue";

const loadingStore = useLoadingStore();

const isLoading = computed(() => loadingStore.isLoading);

const version = import.meta.env.VITE_VERSION || "-";
</script>
<style>
.Notivue__notification {
  z-index: 9999 !important;
}
</style>
