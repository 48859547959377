import Api from "@/utils/api.js";
import Cookies from "js-cookie";

class authServices {
  async login({ params }) {
    const res = await Api.doPost(`login`, params);
    console.log("LOGIN SERVICES", res);
    return res;
  }

  async me() {
    const res = await Api.doGet(`me`);
    console.log("ME SERVICES", res);
    return res;
  }
}

export default new authServices();
