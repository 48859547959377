import { defineStore } from "pinia";
import { reactive, ref, computed } from "vue";

export const useNotificationStore = defineStore("notification", () => {
  const notification = reactive({ id: "", type: "", message: "" });

  const setNotification = ({ type, message }) => {
    notification.id = Math.random();
    notification.type = type;
    notification.message = message;
  };

  return { notification, setNotification };
});
