import Api from "@/utils/api.js";

class userServices {
  async get(params) {
    const res = await Api.doGet(`user`, {
      params,
    });
    console.log("SERVICES USER ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`user/${id}`);
    console.log("SERVICES USER BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`user`, params);
    console.log("SERVICES USER CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`user/${id}`, params);
    console.log("SERVICES USER UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`user/${id}`);
    console.log("SERVICES USER DELETE", res);
    return res;
  }
}

export default new userServices();
