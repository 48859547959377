<template>
  <form class="flex flex-col gap-4 validate-form" @submit.prevent="save">
    <!-- <BaseText
      :validate="validate"
      name="nik"
      label="NIK"
      :disabled="disabledAll"
    ></BaseText> -->

    <BaseText
      :validate="validate"
      name="name"
      label="Name"
      :disabled="disabledAll"
    ></BaseText>

    <BaseText
      :validate="validate"
      name="phone"
      label="Phone"
      :disabled="disabledAll"
    ></BaseText>

    <BaseTextarea
      :validate="validate"
      name="address"
      label="Address"
      :disabled="disabledAll"
    >
    </BaseTextarea>

    <BaseText
      :validate="validate"
      name="email"
      label="Email"
      :disabled="disabledAll"
    ></BaseText>

    <BaseRadio
      :validate="validate"
      name="genderId"
      label="Gender"
      :disabled="disabledAll"
      :options="genderOptions"
    ></BaseRadio>

    <BaseSelectAuto
      :validate="validate"
      name="specialistId"
      label="Specialist"
      :disabled="disabledAll"
      :options="specialistOptions"
    ></BaseSelectAuto>

    <BaseSelectAuto
      :validate="validate"
      name="branchId"
      label="Branch"
      :disabled="disabledAll"
      :options="branchOptions"
    ></BaseSelectAuto>

    <BaseCheckbox
      :validate="validate"
      name="isActive"
      label="Is Active"
      :disabled="disabledAll"
    ></BaseCheckbox>

    <!-- Old Img for Edit and Detail -->
    <template v-if="props.isEdit || props.isDetail">
      <div class="img-preview">
        <label for="img" class="form-label"> Preview </label>

        <img
          v-if="props.data.photo"
          data-action="zoom"
          :src="props.data.photo"
          class="object-cover w-20 h-20"
        />
        <img
          v-else
          data-action="zoom"
          src="@/assets/images/no-photo.jpeg"
          class="object-cover w-20 h-20"
          alt="no-photo"
        />
      </div>
    </template>

    <BaseDropzone
      v-if="!props.isDetail"
      label="Photo"
      @files="onChangeFiles($event)"
    ></BaseDropzone>

    <div class="flex justify-end" v-if="!isDetail">
      <button
        type="button"
        @click="onClickCancel"
        class="w-20 mr-1 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button type="submit" class="w-20 btn btn-primary btn-block">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import { onMounted, reactive, toRefs, computed } from "vue";
import { useEmployeeStore } from "@/stores/modules/employee";
import { useSpecialistStore } from "@/stores/modules/specialist";
import { useBranchStore } from "@/stores/modules/branch";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
  isAdd: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const employeeStore = useEmployeeStore();
const specialistStore = useSpecialistStore();
const branchStore = useBranchStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `employee-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const formData = reactive({
  nik: props.data.nik || "",
  name: props.data.name || "",
  address: props.data.address || "",
  phone: props.data.phone || "",
  email: props.data.email || "",
  isActive: props.data.is_active ? true : false,
  genderId: props.data.gender_id || 1,
  specialistId: parseInt(props.data?.specialist_id) || "",
  branchId: parseInt(props.data?.branch_id) || "",
  files: [],
});

const disabledAll = props.isDetail ? true : false;

const rules = {
  // nik: {
  //   required,
  // },
  name: {
    required,
  },
  address: {
    required,
  },
  phone: {
    required,
  },
  email: {
    required,
    email,
  },
  genderId: {
    required,
  },
  specialistId: {
    required,
  },
  branchId: {
    required,
  },
  isActive: {
    required,
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = new FormData();

  payload.append("nik", formData.nik);
  payload.append("name", formData.name);
  payload.append("address", formData.address);
  payload.append("phone", formData.phone);
  payload.append("email", formData.email);
  payload.append("gender_id", formData.genderId);
  payload.append("specialist_id", formData.specialistId);
  payload.append("branch_id", formData.branchId);
  payload.append("is_active", formData.isActive ? 1 : 0);
  formData.files.forEach((file) => {
    payload.append("photo", file);
  });

  if (props.isEdit) {
    await employeeStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "employee-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await employeeStore.postCreate(payload);

    const propertyModal = {
      id: "employee-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};

const onChangeFiles = (files) => {
  formData.files = files;
};

const genderOptions = [
  {
    text: "Laki-laki",
    value: "1",
  },
  {
    text: "Perempuan",
    value: "2",
  },
];

onMounted(async () => {
  await specialistStore.getAll();
  await branchStore.getAll();
});

const specialistOptions = computed(() => {
  return specialistStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});

const branchOptions = computed(() => {
  return branchStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});
</script>
