<template>
  <form class="validate-form flex flex-col gap-4" @submit.prevent="save">
    <BaseText
      :validate="validate"
      name="nik"
      label="NIK"
      :disabled="disabledAll"
    ></BaseText>

    <BaseText
      :validate="validate"
      name="name"
      label="Name"
      :disabled="disabledAll"
    ></BaseText>

    <BaseTextarea
      :validate="validate"
      name="address"
      label="Address"
      :disabled="disabledAll"
    >
    </BaseTextarea>

    <BaseText
      :validate="validate"
      name="email"
      label="Email"
      :disabled="disabledAll"
    ></BaseText>

    <BaseText
      :validate="validate"
      name="phone"
      label="Phone"
      :disabled="disabledAll"
    ></BaseText>

    <BaseCheckbox
      :validate="validate"
      name="isActive"
      label="Is Active"
      :disabled="disabledAll"
    ></BaseCheckbox>

    <div class="flex justify-end" v-if="!isDetail">
      <button
        type="button"
        @click="onClickCancel"
        class="btn btn-outline-secondary w-20 mr-1"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-primary btn-block w-20">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import { onMounted, reactive, toRefs } from "vue";
import { useCustomerStore } from "@/stores/modules/customer";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const customerStore = useCustomerStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `customer-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const formData = reactive({
  nik: props.data.nik || "",
  name: props.data.name || "",
  address: props.data.address || "",
  email: props.data.email || "",
  phone: props.data.phone || "",
  isActive: props.data.is_active ? true : false,
});

const disabledAll = props.isDetail ? true : false;

const rules = {
  nik: {
    required,
  },
  name: {
    required,
  },
  address: {
    required,
  },
  email: {
    required,
    email,
  },
  phone: {
    required,
  },
  isActive: {
    required,
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = {
    nik: formData.nik,
    name: formData.name,
    address: formData.address,
    email: formData.email,
    phone: formData.phone,
    is_active: formData.isActive ? 1 : 0,
  };

  if (props.isEdit) {
    await customerStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "customer-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await customerStore.postCreate(payload);

    const propertyModal = {
      id: "customer-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};

const onChangeFiles = (files) => {
  formData.files = files;
};
</script>
