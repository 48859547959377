<template>
  <form class="validate-form flex flex-col gap-3" @submit.prevent="save">
    <BaseText
      :validate="validate"
      name="name"
      label="Name"
      :disabled="disabledAll"
    ></BaseText>

    <BaseText
      :validate="validate"
      name="price"
      label="Price"
      :disabled="disabledAll"
    ></BaseText>

    <BaseText
      :validate="validate"
      name="timeSpan"
      label="Time Span"
      :disabled="disabledAll"
    ></BaseText>

    <BaseSelect
      :validate="validate"
      name="category"
      label="Category"
      :disabled="disabledAll"
      :options="categoryOptions"
    ></BaseSelect>

    <BaseText
      :validate="validate"
      name="session"
      label="Session"
      :disabled="disabledAll"
    ></BaseText>

    <BaseCheckbox
      :validate="validate"
      name="isActive"
      label="Is Active"
      :disabled="disabledAll"
    ></BaseCheckbox>

    <div class="flex justify-end" v-if="!isDetail">
      <button
        type="button"
        @click="onClickCancel"
        class="btn btn-outline-secondary w-20 mr-1"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-primary btn-block w-20">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { onMounted, reactive, toRefs, ref, computed } from "vue";
import { useProductStore } from "@/stores/modules/product";
import { useCategoryStore } from "@/stores/modules/category";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const productStore = useProductStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `product-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const disabledAll = props.isDetail ? true : false;

const formData = reactive({
  name: props.data.name || "",
  price: props.data.price || "",
  timeSpan: props.data.time_span || "",
  category: props.data.category?.id || "",
  session: props.data.session || "",
  isActive: props.data.is_active ? true : false,
});

const rules = {
  name: {
    required,
  },
  price: {
    required,
  },
  timeSpan: {
    required,
  },
  category: {
    required,
  },
  session: {
    required,
  },
  isActive: {
    required,
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = {
    name: formData.name,
    price: formData.price,
    time_span: formData.timeSpan,
    category_id: formData.category,
    session: formData.session,
    is_active: formData.isActive ? 1 : 0,
  };

  if (props.isEdit) {
    await productStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "product-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await productStore.postCreate(payload);

    const propertyModal = {
      id: "product-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};

const categoryStore = useCategoryStore();

onMounted(async () => {
  await categoryStore.getAll();
});

const categoryOptions = computed(() => {
  return categoryStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});
</script>
