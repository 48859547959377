<template>
  <form class="validate-form flex flex-col gap-3" @submit.prevent="save">
    <BaseText :validate="validate" name="name" label="Name"></BaseText>

    <div class="flex justify-end">
      <button
        type="button"
        @click="onClickCancel"
        class="btn btn-outline-secondary w-20 mr-1"
      >
        Cancel
      </button>
      <button type="submit" class="btn btn-primary btn-block w-20">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { onMounted, reactive, toRefs } from "vue";
import { useCategoryStore } from "@/stores/modules/category";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const categoryStore = useCategoryStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `category-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const formData = reactive({
  name: props.data.name || "",
});

const rules = {
  name: {
    required,
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = new FormData();
  payload.append("name", formData.name);
  payload.append("is_active", formData.is_active ? 1 : 0);

  if (props.isEdit) {
    await categoryStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "category-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await categoryStore.postCreate(formData);

    const propertyModal = {
      id: "category-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};
</script>
