import { defineStore } from "pinia";

export const useSideMenuStore = defineStore("sideMenu", {
  state: () => ({
    menu: [
      {
        icon: "HomeIcon",
        pageName: "side-menu-home",
        title: "Home",
      },
      // {
      //   icon: "HomeIcon",
      //   pageName: "side-menu-session",
      //   title: "Session",
      // },
      {
        icon: "HomeIcon",
        pageName: "side-menu-purchase-order",
        title: "Purchase Order",
      },
      // {
      //   icon: "HomeIcon",
      //   pageName: "side-menu-page-1",
      //   title: "Page 1",
      // },
      // {
      //   icon: "HomeIcon",
      //   pageName: "side-menu-page-2",
      //   title: "Page 2",
      // },
      {
        icon: "HomeIcon",
        pageName: "side-menu-master",
        title: "Master",
        subMenu: [
          {
            icon: "HomeIcon",
            pageName: "side-menu-branch",
            title: "Branch",
          },
          {
            icon: "HomeIcon",
            pageName: "side-menu-customer",
            title: "Customer",
          },
          {
            icon: "HomeIcon",
            pageName: "side-menu-employee",
            title: "Employee",
          },
          {
            icon: "HomeIcon",
            pageName: "side-menu-product",
            title: "Product",
          },
          {
            icon: "HomeIcon",
            pageName: "side-menu-promo",
            title: "Promo",
          },
          // {
          //   icon: "HomeIcon",
          //   pageName: "side-menu-role",
          //   title: "Role",
          // },
          // {
          //   icon: "HomeIcon",
          //   pageName: "side-menu-user",
          //   title: "User",
          // },
          {
            icon: "HomeIcon",
            pageName: "side-menu-category",
            title: "Category",
          },
          {
            icon: "HomeIcon",
            pageName: "side-menu-specialist",
            title: "Specialist",
          },
        ],
      },
    ],
  }),
});
