import Api from "@/utils/api.js";

class promoServices {
  async get(params) {
    const res = await Api.doGet(`promo`, {
      params,
    });
    console.log("SERVICES PROMO ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`promo/${id}`);
    console.log("SERVICES PROMO BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`promo`, params);
    console.log("SERVICES PROMO CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`promo/${id}`, params);
    console.log("SERVICES PROMO UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`promo/${id}`);
    console.log("SERVICES PROMO DELETE", res);
    return res;
  }
}

export default new promoServices();
