<template>
  <form class="flex flex-col gap-3 validate-form" @submit.prevent="save">
    <BaseText :validate="validate" name="name" label="Name"></BaseText>

    <div class="flex justify-end">
      <button
        type="button"
        @click="onClickCancel"
        class="w-20 mr-1 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button type="submit" class="w-20 btn btn-primary btn-block">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { onMounted, reactive, toRefs } from "vue";
import { useSpecialistStore } from "@/stores/modules/specialist";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const specialistStore = useSpecialistStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `specialist-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const formData = reactive({
  name: props.data.name || "",
});

const rules = {
  name: {
    required,
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = new FormData();
  payload.append("name", formData.name);
  payload.append("is_active", formData.is_active ? 1 : 0);

  if (props.isEdit) {
    await specialistStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "specialist-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await specialistStore.postCreate(formData);

    const propertyModal = {
      id: "specialist-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};
</script>
