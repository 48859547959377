import { computed, reactive, ref } from "vue";
import { defineStore } from "pinia";
import Services from "@/services/specialist";
import {
  showSuccessPopup,
  showFailedPopup,
  showConfirmPopup,
} from "../../utils/popup";
import { useRoute, useRouter } from "vue-router";

export const useSpecialistStore = defineStore("specialist", () => {
  const route = useRoute();
  const router = useRouter();
  const query = reactive({
    per_page: route.query.per_page || 10,
    page: route.query.page || 1,
    order_by: "ID",
    sort: "DESC",
    search: route.query.search || "",
  });

  const setQuery = (state, value) => {
    query[state] = value;

    router.push({
      query: query,
    });
  };

  const lists = ref(null);
  async function getAll() {
    const res = await Services.get(query);
    console.log("STORE GET SPECIALIST ALL", res);

    lists.value = res?.data?.data;
    return res;
  }

  async function getById(id) {
    const res = await Services.getById(id);
    console.log("STORE GET SPECIALIST BY ID", res);

    return res;
  }

  async function postCreate(params) {
    const res = await Services.postCreate(params);
    console.log("STORE CREATE SPECIALIST", res);

    if (res?.data?.code === 200 || res?.status === 200 || res?.code === 201) {
      showSuccessPopup("Success", "Specialist created successfully");

      getAll();
    } else {
      showFailedPopup("Failed", "Specialist failed to create");
    }

    return res;
  }

  async function putUpdate(id, params) {
    const res = await Services.putUpdate(id, params);
    console.log("STORE UPDATE SPECIALIST", res);

    if (res?.data?.code === 200 || res?.status === 200) {
      showSuccessPopup("Success", "Specialist updated successfully");

      getAll();
      return res;
    } else {
      showFailedPopup("Failed", "Specialist failed to update");

      return res;
    }
  }

  async function deleteById(id) {
    showConfirmPopup("Are you sure?", "You won't be able to revert this!").then(
      async (result) => {
        if (result.isConfirmed) {
          const res = await Services.delete(id);

          console.log("STORE DELETE SPECIALIST", res);

          if (res?.data?.code === 200 || res?.status === 200) {
            showSuccessPopup("Success", "Specialist deleted successfully");

            getAll();
          } else {
            showFailedPopup("Failed", "Specialist failed to delete");
          }

          return res;
        }
      }
    );
  }

  return { getAll, setQuery, lists, deleteById, postCreate, putUpdate };
});
