<template>
  <form class="flex flex-col gap-4 validate-form" @submit.prevent="save">
    <BaseText
      :validate="validate"
      name="name"
      label="Name"
      :disabled="disabledAll"
    ></BaseText>

    <BaseText
      :validate="validate"
      name="phone"
      label="Phone"
      :disabled="disabledAll"
    ></BaseText>

    <BaseTextarea
      :validate="validate"
      name="address"
      label="Address"
      :disabled="disabledAll"
    >
    </BaseTextarea>

    <BaseText
      :validate="validate"
      name="url"
      label="Url Location"
      :disabled="disabledAll"
    ></BaseText>

    <BaseCheckbox
      :validate="validate"
      name="isActive"
      label="Is Active"
      :disabled="disabledAll"
    ></BaseCheckbox>

    <!-- Old Img for Edit and Detail -->
    <template v-if="props.isEdit || props.isDetail">
      <label for="img" class="form-label"> Preview </label>
      <div class="flex flex-row gap-2">
        <div v-for="photo in props.data.branch_photos" :key="photo.id">
          <img
            v-if="photo.url"
            :alt="photo.url"
            data-action="zoom"
            :src="photo.url"
            class="object-cover w-20 h-20"
          />
        </div>
      </div>
    </template>

    <BaseDropzone
      v-if="!props.isDetail"
      label="Photo"
      @files="onChangeFiles($event)"
    ></BaseDropzone>

    <div class="flex justify-end" v-if="!isDetail">
      <button
        type="button"
        @click="onClickCancel"
        class="w-20 mr-1 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button type="submit" class="w-20 btn btn-primary btn-block">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import { onMounted, reactive, toRefs } from "vue";
import { useBranchStore } from "@/stores/modules/branch";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const branchStore = useBranchStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `branch-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const formData = reactive({
  name: props.data.name || "",
  address: props.data.address || "",
  phone: props.data.phone || "",
  isActive: props.data.is_active ? true : false,
  url: props.data.url || "",
  files: [],
});

const disabledAll = props.isDetail ? true : false;

const rules = {
  name: {
    required,
    minLength: minLength(2),
  },
  address: {
    required,
  },

  phone: {
    required,
  },
  isActive: {
    required,
  },
  url: {
    required,
  },
  // lat: {
  //   required,
  // },
  // lng: {
  //   required,
  // },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = new FormData();

  payload.append("name", formData.name);
  payload.append("address", formData.address);
  payload.append("phone", formData.phone);
  payload.append("is_active", formData.isActive ? 1 : 0);
  payload.append("url", formData.url);
  formData.files.forEach((file) => {
    payload.append("photos[]", file);
  });

  if (props.isEdit) {
    await branchStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "branch-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await branchStore.postCreate(payload);

    const propertyModal = {
      id: "branch-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};

const onChangeFiles = (files) => {
  formData.files = files;
};
</script>
