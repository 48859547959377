<template>
  <FullCalendar :options="options" />
</template>

<script setup>
import "@fullcalendar/core/vdom";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import dom from "@left4code/tw-starter/dist/js/dom";
import { helper } from "@/utils/helper.js";
import { useModalStore } from "@/stores/modal.js";

const modalStore = useModalStore();

const props = defineProps({
  events: {
    type: [Array],
    required: true,
    default: [],
  },
});

const options = {
  plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
  // droppable: true,
  // headerToolbar: {
  //   left: "prev,next today",
  //   center: "title",
  //   right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
  // },
  locale: "id",
  initialDate: `${helper.formatDate(new Date(), "YYYY-MM")}-01`,
  initialView: "listMonth",
  timeZone: "UTC",
  // navLinks: true,
  // editable: false,
  // dayMaxEvents: true,
  events: props.events,

  eventClick: function (info) {
    info.jsEvent.preventDefault();

    const propertyModal = {
      id: "home-calendar",
      show: true,
      size: "sm",
      data: info.event,
    };

    modalStore.setModal(propertyModal);
  },
};
</script>
