import BaseText from "./text/Main.vue";
import BaseCheckbox from "./checkbox/Main.vue";
import BaseTextarea from "./textarea/Main.vue";
import BaseDropzone from "./dropzone/Main.vue";
import BaseRadio from "./radio/Main.vue";
import BaseSelect from "./select/Main.vue";
import BaseInput from "./input/Main.vue";
import BaseDate from "./date/Main.vue";
import BaseSelectAuto from "./select-auto/Main.vue";

export default (app) => {
  app.component("BaseText", BaseText);
  app.component("BaseCheckbox", BaseCheckbox);
  app.component("BaseTextarea", BaseTextarea);
  app.component("BaseDropzone", BaseDropzone);
  app.component("BaseRadio", BaseRadio);
  app.component("BaseSelect", BaseSelect);
  app.component("BaseInput", BaseInput);
  app.component("BaseDate", BaseDate);
  app.component("BaseSelectAuto", BaseSelectAuto);
};
