import Api from "@/utils/api.js";

class specialistServices {
  async get(params) {
    const res = await Api.doGet(`specialist`, {
      params,
    });
    console.log("SERVICES SPECIALIST ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`specialist/${id}`);
    console.log("SERVICES SPECIALIST BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`specialist`, params);
    console.log("SERVICES SPECIALIST CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`specialist/${id}`, params);
    console.log("SERVICES SPECIALIST UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`specialist/${id}`);
    console.log("SERVICES SPECIALIST DELETE", res);
    return res;
  }
}

export default new specialistServices();
