<template>
  <form class="flex flex-col gap-3 validate-form" @submit.prevent="save">
    <BaseSelectAuto
      :validate="validate"
      name="customer"
      label="Customer"
      :disabled="disabledAll"
      :options="customerOptions"
    ></BaseSelectAuto>

    <BaseSelectAuto
      :validate="validate"
      name="product"
      label="Product"
      :disabled="disabledAll"
      :options="productOptions"
    ></BaseSelectAuto>

    <BaseCheckbox
      :validate="validate"
      name="isPromo"
      label="Is Promo"
      :disabled="disabledAll"
    ></BaseCheckbox>

    <BaseSelectAuto
      :validate="validate"
      name="promo"
      label="Promo"
      v-if="formData.isPromo"
      :disabled="disabledAll"
      :options="promoOptions"
    ></BaseSelectAuto>

    <template v-if="isEdit || isDetail">
      <BaseCheckbox
        :validate="validate"
        name="isExtend"
        label="Is Extend"
        :disabled="disabledAll"
      ></BaseCheckbox>

      <BaseText
        v-if="formData.isExtend"
        :validate="validate"
        name="extendedDay"
        label="Extended Day"
        :disabled="disabledAll"
      ></BaseText>
    </template>

    <!-- <BaseCheckbox
      :validate="validate"
      name="isActive"
      label="Is Active"
      :disabled="disabledAll"
    ></BaseCheckbox> -->

    <!-- <template :class="{ 'd-none': isEdit || isDetail }"> -->
    <div :class="{ hidden: isAdd }">
      <FormSession
        @update:sessions="updateSessions"
        v-if="session > 0"
        :session="session"
        :is-detail="isDetail"
        :is-edit="isEdit"
        :sessionsData="newSessionData"
        :disabled="disabledAll"
        :is-add="isAdd"
      ></FormSession>
    </div>
    <!-- </template> -->

    <div class="flex justify-end" v-if="!isDetail">
      <button
        type="button"
        @click="onClickCancel"
        class="w-20 mr-1 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button type="submit" class="w-20 btn btn-primary btn-block">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import FormSession from "./FormSession.vue";
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  requiredIf,
  numeric,
} from "@vuelidate/validators";
import { onMounted, reactive, toRefs, ref, computed, watch } from "vue";
import { usePurchaseOrderStore } from "@/stores/modules/purchase-order";
import { useCategoryStore } from "@/stores/modules/category";
import { useProductStore } from "@/stores/modules/product";
import { useCustomerStore } from "@/stores/modules/customer";
import { usePromoStore } from "@/stores/modules/promo";
import { useEmployeeStore } from "@/stores/modules/employee";
import { showFailedPopup } from "@/utils/popup";
import { helper } from "@/utils/helper.js";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
  isAdd: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const purchaseOrderStore = usePurchaseOrderStore();
const productStore = useProductStore();
const customerStore = useCustomerStore();
const promoStore = usePromoStore();
const employeeStore = useEmployeeStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `purchase-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const disabledAll = props.isDetail ? true : false;

const formData = reactive({
  customer: props.data?.customer_id || "",
  product: props.data?.product_id || "",
  promo: props.data?.promo_id || "",
  isPromo: props.data?.promo_id ? true : false,
  isActive: props.data?.is_active ? true : props.isAdd ? true : false,
  isExtend: props.data?.extended_day > 0 ? true : false,
  sessions: props.data?.sessions || [],
  extendedDay: props.data?.extended_day || 0,
});

const rules = {
  customer: {
    required,
  },
  product: {
    required,
  },
  isPromo: {},
  promo: {
    required: requiredIf(() => formData.isPromo),
  },
  // isActive: {
  //   required,
  // },
  isExtend: {},
  extendedDay: {
    required: requiredIf(() => formData.isExtend),
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  // if (formData.sessions.length > 0) {
  //   let checkEmpty = false;

  //   // Check empty item
  //   formData.sessions.forEach((item) => {
  //     if (Object.values(item).includes("")) {
  //       checkEmpty = true;
  //       return;
  //     }
  //   });

  //   if (checkEmpty) {
  //     showFailedPopup(400, "Please fill all session");
  //     return;
  //   }
  // }

  const payload = {
    customer_id: formData.customer,
    product_id: formData.product,
    promo_id: formData.isPromo ? formData.promo : null,
    sessions: formData.sessions,
    extended_day: formData.isExtend ? parseInt(formData.extendedDay) : null,
    // is_active: formData.isActive ? 1 : 0,
  };

  if (props.isEdit) {
    await purchaseOrderStore.putWithSessionUpdate(props.data.id, payload);
    const propertyModal = {
      id: "purchase-order-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await purchaseOrderStore.postWithSessionCreate(payload);

    const propertyModal = {
      id: "purchase-order-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};

// const categoryStore = useCategoryStore();

onMounted(async () => {
  // await categoryStore.getAll();
  productStore.setQuery("active", 1);
  customerStore.setQuery("active", 1);
  promoStore.setQuery("active", 1);
  await productStore.getAll();
  await customerStore.getAll();
  await promoStore.getAll();
});

const productOptions = computed(() => {
  return productStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});

const customerOptions = computed(() => {
  return customerStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});

const promoOptions = computed(() => {
  return promoStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});

const session = computed(() => {
  return (
    productStore.lists?.data
      .filter((item) => {
        return item.id === formData.product;
      })
      .map((item) => {
        return {
          session: item.session,
        };
      })?.[0]?.session || 0
  );
});

const updateSessions = (sessions) => {
  formData.sessions = sessions;
};

// Format Date
const newSessionData = computed(() => {
  return props.data.sessions?.map((item) => {
    return {
      ...item,
      date: helper.formatDate(item.date, "YYYY-MM-DD"),
    };
  });
});
</script>
