import { computed, reactive, ref } from "vue";
import { defineStore } from "pinia";
import Services from "@/services/purchase-order-item";
import {
  showSuccessPopup,
  showFailedPopup,
  showConfirmPopup,
} from "../../utils/popup";
import { useRoute, useRouter } from "vue-router";
import { helper } from "../../utils/helper";

export const usePurchaseOrderItemStore = defineStore(
  "purchase-order-item",
  () => {
    const route = useRoute();
    const router = useRouter();
    const query = reactive({
      per_page: route.query.per_page || 10,
      page: route.query.page || 1,
      order_by: "ID",
      sort: "DESC",
      search: route.query.search || "",
    });

    const setQuery = (state, value) => {
      query[state] = value;

      router.push({
        query: query,
      });
    };

    const queryExport = reactive({
      start_date: route.query.start_date || "",
      end_date: route.query.end_date || "",
    });

    const setQueryExport = (state, value) => {
      queryExport[state] = value;

      router.push({
        query: queryExport,
      });
    };

    const lists = ref(null);
    async function getAll() {
      const res = await Services.get(query);
      console.log("STORE GET PURCHASE ORDER ITEM ALL", res);

      lists.value = res?.data?.data;
      return res;
    }

    async function getById(id) {
      const res = await Services.getById(id);
      console.log("STORE GET PURCHASE ORDER ITEM BY ID", res);

      return res;
    }

    async function postCreate(params) {
      const res = await Services.postCreate(params);
      console.log("STORE CREATE PURCHASE ORDER ITEM", res);

      if (res?.data?.code === 200 || res?.status === 200 || res?.code === 201) {
        showSuccessPopup("Success", "Purchase Order created successfully");

        getAll();
      } else {
        showFailedPopup("Failed", "Purchase Order failed to create");
      }

      return res;
    }

    async function putUpdate(id, params) {
      const res = await Services.putUpdate(id, params);
      console.log("STORE UPDATE PURCHASE ORDER ITEM", res);

      if (res?.data?.code === 200 || res?.status === 200) {
        showSuccessPopup("Success", "Purchase Order updated successfully");

        getAll();
        return res;
      } else {
        showFailedPopup("Failed", "Purchase Order failed to update");

        return res;
      }
    }

    async function deleteById(id) {
      showConfirmPopup(
        "Are you sure?",
        "You won't be able to revert this!"
      ).then(async (result) => {
        if (result.isConfirmed) {
          const res = await Services.delete(id);

          console.log("STORE DELETE PURCHASE ORDER ITEM", res);

          if (res?.data?.code === 200 || res?.status === 200) {
            showSuccessPopup("Success", "Purchase Order deleted successfully");

            getAll();
          } else {
            showFailedPopup("Failed", "Purchase Order failed to delete");
          }

          return res;
        }
      });
    }

    async function exportExcel() {
      const res = await Services.exportExcel(queryExport);
      console.log("STORE EXPORT PURCHASE ITEM ORDER", res);

      if (res?.data?.code === 200 || res?.status === 200) {
        const filename = `purchase-order-item-${new Date().toISOString()}.xlsx`;
        helper.downloadFile(
          res.data,
          filename,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      }

      return res;
    }

    const uuidLists = ref([]);
    async function getUuid(uuidParams) {
      const res = await Services.getUuid(uuidParams);
      console.log("STORE GET PURCHASE ORDER ITEM UUID", res);

      uuidLists.value = res?.data;
      return res;
    }

    return {
      getAll,
      setQuery,
      lists,
      deleteById,
      postCreate,
      putUpdate,
      setQueryExport,
      exportExcel,
      uuidLists,
      getUuid,
    };
  }
);
