<template>
  <p class="font-bold">Session</p>

  <div class="flex flex-col gap-2 box p-2">
    <div
      v-if="session > 0 && isAdd"
      v-for="(item, index) in sessions"
      class="flex flex-row gap-3"
    >
      <div class="grid grid-cols-4 gap-5">
        <div class="employee">
          <label for="employee">Staff Fisio</label>
          <select
            class="form-select form-select-md"
            aria-label=".form-select-lg example"
            id="employee"
            v-model.trim="sessions[index].employee_id"
            name="employee"
            :disabled="disabled"
          >
            <option selected value="">Select this option</option>
            <option v-for="option in employeeOptions" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="date">
          <label for="date">Date</label>
          <input
            id="date"
            v-model.trim="sessions[index].date"
            type="date"
            name="date"
            :disabled="disabled"
            class="form-control"
            placeholder="Date"
          />
        </div>
        <div class="time">
          <label for="time">Time</label>

          <input
            id="time"
            v-model.trim="sessions[index].time"
            type="time"
            :disabled="disabled"
            name="name"
            class="form-control"
          />
        </div>

        <div class="time">
          <label for="time">Status</label>

          <select
            class="form-select form-select-md"
            aria-label=".form-select-lg example"
            id="status"
            v-model.trim="sessions[index].status"
            name="status"
            :disabled="disabled"
          >
            <option selected value="">Select this option</option>
            <option v-for="option in statusOptions" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div
      v-else-if="sessions.length > 0 && (isEdit || isDetail)"
      v-for="(item, index) in sessions"
      class="flex flex-row gap-3"
    >
      <div class="grid grid-cols-4 gap-5">
        <div class="employee">
          <label for="employee">Staff Fisio</label>
          <select
            class="form-select form-select-md"
            aria-label=".form-select-lg example"
            id="employee"
            v-model.trim="item.employee_id"
            name="employee"
            :disabled="disabled"
          >
            <option selected value="">Select this option</option>
            <option v-for="option in employeeOptions" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="date">
          <label for="date">Date</label>
          <input
            id="date"
            v-model.trim="item.date"
            type="date"
            name="date"
            :disabled="disabled"
            class="form-control"
            placeholder="Date"
          />
        </div>
        <div class="time">
          <label for="time">Time</label>

          <input
            id="time"
            v-model.trim="item.time"
            type="time"
            :disabled="disabled"
            name="name"
            class="form-control"
          />
        </div>

        <div class="time">
          <label for="time">Status</label>

          <select
            class="form-select form-select-md"
            aria-label=".form-select-lg example"
            id="status"
            v-model.trim="item.status"
            name="status"
            :disabled="disabled"
          >
            <option selected value="">Select this option</option>
            <option v-for="option in statusOptions" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center">No session available</p>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { onMounted, reactive, toRefs, ref, computed, watch } from "vue";

import { useEmployeeStore } from "@/stores/modules/employee";

const props = defineProps({
  sessionsData: {
    type: Array,
    default: [],
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
  isAdd: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  session: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(["update:session"]);

const employeeStore = useEmployeeStore();

const sessions = ref(props.sessionsData || []);

watch(
  () => props.session,
  (value) => {
    console.log("watch session", value);

    if (props.isAdd || props.isEdit) {
      const newArray = [];

      for (let i = 0; i < value; i++) {
        newArray.push({
          employee_id: "",
          date: "",
          time: "",
          status: "",
        });
      }

      sessions.value = newArray;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => sessions.value,
  (value) => {
    console.log("watch sessions", value);

    const newArray = [];

    for (let i = 0; i < value.length; i++) {
      newArray.push({
        employee_id: value[i].employee_id || null,
        date: isValidDate(value[i].date) ? value[i].date : null,
        time: value[i].time || null,
        status: value[i].status || null,
      });
    }

    emits("update:sessions", newArray);
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(
  () => props.sessionsData,
  (value) => {
    console.log("watch sessionsData", value);

    if (props.isEdit || props.isDetail) {
      sessions.value = value;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(async () => {
  await employeeStore.getAll();
});

const employeeOptions = computed(() => {
  return employeeStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});

const statusOptions = [
  {
    label: "Progress",
    value: 1,
  },
  {
    label: "Attended",
    value: 2,
  },
  {
    label: "Burned",
    value: 3,
  },
  {
    label: "Reschedule",
    value: 4,
  },
  {
    label: "Finished",
    value: 5,
  },
];

function isValidDate(dateObject) {
  return new Date(dateObject).toString() !== "Invalid Date";
}
</script>
