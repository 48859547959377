<template>
  <TestModalFirst v-if="isModal?.id === 'modal-first'" :data="isModal.data" />
  <TestModalTwo v-if="isModal?.id === 'modal-two'" :data="isModal.data" />

  <!-- Home -->
  <HomeModalCalendar
    v-if="isModal?.id === 'home-calendar'"
    :data="isModal.data"
  />

  <!-- Branch -->
  <BranchModalEdit v-if="isModal?.id === 'branch-edit'" :data="isModal.data" />
  <BranchModalAdd v-if="isModal?.id === 'branch-add'" :data="isModal.data" />
  <BranchModalDetail
    v-if="isModal?.id === 'branch-detail'"
    :data="isModal.data"
  />

  <!-- Customer= -->
  <CustomerModalEdit
    v-if="isModal?.id === 'customer-edit'"
    :data="isModal.data"
  />
  <CustomerModalAdd
    v-if="isModal?.id === 'customer-add'"
    :data="isModal.data"
  />
  <CustomerModalDetail
    v-if="isModal?.id === 'customer-detail'"
    :data="isModal.data"
  />

  <!-- Employee -->
  <EmployeeModalEdit
    v-if="isModal?.id === 'employee-edit'"
    :data="isModal.data"
  />
  <EmployeeModalAdd
    v-if="isModal?.id === 'employee-add'"
    :data="isModal.data"
  />
  <EmployeeModalDetail
    v-if="isModal?.id === 'employee-detail'"
    :data="isModal.data"
  />

  <!-- Product -->
  <ProductModalEdit
    v-if="isModal?.id === 'product-edit'"
    :data="isModal.data"
  />
  <ProductModalAdd v-if="isModal?.id === 'product-add'" :data="isModal.data" />
  <ProductModalDetail
    v-if="isModal?.id === 'product-detail'"
    :data="isModal.data"
  />

  <!-- Product Item -->
  <ProductItemModalEdit
    v-if="isModal?.id === 'product-item-edit'"
    :data="isModal.data"
  />
  <ProductItemModalAdd
    v-if="isModal?.id === 'product-item-add'"
    :data="isModal.data"
  />
  <ProductItemModalDetail
    v-if="isModal?.id === 'product-item-detail'"
    :data="isModal.data"
  />

  <!-- Promo -->
  <PromoModalEdit v-if="isModal?.id === 'promo-edit'" :data="isModal.data" />
  <PromoModalAdd v-if="isModal?.id === 'promo-add'" :data="isModal.data" />
  <PromoModalDetail
    v-if="isModal?.id === 'promo-detail'"
    :data="isModal.data"
  />

  <!-- Role -->
  <RoleModalEdit v-if="isModal?.id === 'role-edit'" :data="isModal.data" />
  <RoleModalAdd v-if="isModal?.id === 'role-add'" :data="isModal.data" />
  <RoleModalDetail v-if="isModal?.id === 'role-detail'" :data="isModal.data" />

  <!-- User -->
  <UserModalEdit v-if="isModal?.id === 'user-edit'" :data="isModal.data" />
  <UserModalAdd v-if="isModal?.id === 'user-add'" :data="isModal.data" />
  <UserModalDetail v-if="isModal?.id === 'user-detail'" :data="isModal.data" />

  <!-- Category -->
  <CategoryModalEdit
    v-if="isModal?.id === 'category-edit'"
    :data="isModal.data"
  />
  <CategoryModalAdd
    v-if="isModal?.id === 'category-add'"
    :data="isModal.data"
  />
  <CategoryModalDetail
    v-if="isModal?.id === 'category-detail'"
    :data="isModal.data"
  />

  <!-- Purchase Order -->
  <PurchaseOrderModalEdit
    v-if="isModal?.id === 'purchase-order-edit'"
    :data="isModal.data"
  />
  <PurchaseOrderModalAdd
    v-if="isModal?.id === 'purchase-order-add'"
    :data="isModal.data"
  />
  <PurchaseOrderModalDetail
    v-if="isModal?.id === 'purchase-order-detail'"
    :data="isModal.data"
  />
  <PurchaseOrderModalExportExcel
    v-if="isModal?.id === 'purchase-order-export-excel'"
    :data="isModal.data"
  />

  <!-- Purchase Order Item -->
  <PurchaseOrderItemModalEdit
    v-if="isModal?.id === 'purchase-order-item-edit'"
    :data="isModal.data"
  />
  <PurchaseOrderItemModalAdd
    v-if="isModal?.id === 'purchase-order-item-add'"
    :data="isModal.data"
  />
  <PurchaseOrderItemModalDetail
    v-if="isModal?.id === 'purchase-order-item-detail'"
    :data="isModal.data"
  />
  <PurchaseOrderItemModalExportExcel
    v-if="isModal?.id === 'purchase-order-item-export-excel'"
    :data="isModal.data"
  />

  <!-- Specialist -->
  <SpecialistModalEdit
    v-if="isModal?.id === 'specialist-edit'"
    :data="isModal.data"
  />
  <SpecialistModalDetail
    v-if="isModal?.id === 'specialist-detail'"
    :data="isModal.data"
  />
  <SpecialistModalAdd
    v-if="isModal?.id === 'specialist-add'"
    :data="isModal.data"
  />
</template>
<script setup>
import TestModalFirst from "@/components/features/testing/ModalFirst.vue";
import TestModalTwo from "@/components/features/testing/ModalTwo.vue";

import HomeModalCalendar from "@/components/features/home/ModalCalendar.vue";
import PromoModalEdit from "@/components/features/promo/ModalEdit.vue";
import PromoModalAdd from "@/components/features/promo/ModalAdd.vue";
import PromoModalDetail from "@/components/features/promo/ModalDetail.vue";
import BranchModalEdit from "@/components/features/branch/ModalEdit.vue";
import BranchModalAdd from "@/components/features/branch/ModalAdd.vue";
import BranchModalDetail from "@/components/features/branch/ModalDetail.vue";
import CustomerModalEdit from "@/components/features/customer/ModalEdit.vue";
import CustomerModalAdd from "@/components/features/customer/ModalAdd.vue";
import CustomerModalDetail from "@/components/features/customer/ModalDetail.vue";
import EmployeeModalEdit from "@/components/features/employee/ModalEdit.vue";
import EmployeeModalAdd from "@/components/features/employee/ModalAdd.vue";
import EmployeeModalDetail from "@/components/features/employee/ModalDetail.vue";
import ProductModalEdit from "@/components/features/product/ModalEdit.vue";
import ProductModalAdd from "@/components/features/product/ModalAdd.vue";
import ProductModalDetail from "@/components/features/product/ModalDetail.vue";
import ProductItemModalEdit from "@/components/features/product-item/ModalEdit.vue";
import ProductItemModalAdd from "@/components/features/product-item/ModalAdd.vue";
import ProductItemModalDetail from "@/components/features/product-item/ModalDetail.vue";
import RoleModalEdit from "@/components/features/role/ModalEdit.vue";
import RoleModalAdd from "@/components/features/role/ModalAdd.vue";
import RoleModalDetail from "@/components/features/role/ModalDetail.vue";
import UserModalEdit from "@/components/features/user/ModalEdit.vue";
import UserModalAdd from "@/components/features/user/ModalAdd.vue";
import UserModalDetail from "@/components/features/user/ModalDetail.vue";
import CategoryModalEdit from "@/components/features/category/ModalEdit.vue";
import CategoryModalAdd from "@/components/features/category/ModalAdd.vue";
import CategoryModalDetail from "@/components/features/category/ModalDetail.vue";
import PurchaseOrderModalEdit from "@/components/features/purchase-order/ModalEdit.vue";
import PurchaseOrderModalAdd from "@/components/features/purchase-order/ModalAdd.vue";
import PurchaseOrderModalDetail from "@/components/features/purchase-order/ModalDetail.vue";
import PurchaseOrderModalExportExcel from "@/components/features/purchase-order/ModalExportExcel.vue";
import PurchaseOrderItemModalEdit from "@/components/features/purchase-order-item/ModalEdit.vue";
import PurchaseOrderItemModalAdd from "@/components/features/purchase-order-item/ModalAdd.vue";
import PurchaseOrderItemModalDetail from "@/components/features/purchase-order-item/ModalDetail.vue";
import PurchaseOrderItemModalExportExcel from "@/components/features/purchase-order-item/ModalExportExcel.vue";
import SpecialistModalEdit from "@/components/features/specialist/ModalEdit.vue";
import SpecialistModalAdd from "@/components/features/specialist/ModalAdd.vue";
import SpecialistModalDetail from "@/components/features/specialist/ModalDetail.vue";

import { useModalStore } from "@/stores/modal";
import { computed } from "vue";

const modalStore = useModalStore();

const isModal = computed(() => modalStore.isModal);
</script>
