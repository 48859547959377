import Api from "@/utils/api.js";

class employeeServices {
  async get(params) {
    const res = await Api.doGet(`employee`, {
      params,
    });
    console.log("SERVICES EMPLOYEE ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`employee/${id}`);
    console.log("SERVICES EMPLOYEE BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPostMultipart(`employee`, params);
    console.log("SERVICES EMPLOYEE CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPostMultipart(`employee/${id}`, params);
    console.log("SERVICES EMPLOYEE UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`employee/${id}`);
    console.log("SERVICES EMPLOYEE DELETE", res);
    return res;
  }
}

export default new employeeServices();
