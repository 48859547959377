import Api from "@/utils/api.js";

class productItemServices {
  async get(params) {
    const res = await Api.doGet(`product-item`, {
      params,
    });
    console.log("SERVICES PRODUCT ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`product-item/${id}`);
    console.log("SERVICES PRODUCT BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`product-item`, params);
    console.log("SERVICES PRODUCT CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`product-item/${id}`, params);
    console.log("SERVICES PRODUCT UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`product-item/${id}`);
    console.log("SERVICES PRODUCT DELETE", res);
    return res;
  }
}

export default new productItemServices();
