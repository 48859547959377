<template>
  <form class="flex flex-col gap-3 validate-form" @submit.prevent="save">
    <BaseText
      :validate="validate"
      name="name"
      label="Name"
      :disabled="disabledAll"
    ></BaseText>

    <BaseText
      :validate="validate"
      name="price"
      label="Price"
      :disabled="disabledAll"
    ></BaseText>

    <BaseCheckbox
      :validate="validate"
      name="isActive"
      label="Is Active"
      :disabled="disabledAll"
    ></BaseCheckbox>

    <div class="flex justify-end" v-if="!isDetail">
      <button
        type="button"
        @click="onClickCancel"
        class="w-20 mr-1 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button type="submit" class="w-20 btn btn-primary btn-block">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { onMounted, reactive, toRefs, ref, computed } from "vue";
import { useProductItemStore } from "@/stores/modules/product-item";
import { useCategoryStore } from "@/stores/modules/category";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const productItemStore = useProductItemStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `product-item-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const disabledAll = props.isDetail ? true : false;

const formData = reactive({
  name: props.data.name || "",
  price: props.data.price || "",
  isActive: props.data.is_active ? true : false,
});

const rules = {
  name: {
    required,
  },
  price: {
    required,
  },
  isActive: {
    required,
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = {
    name: formData.name,
    price: formData.price,
    is_active: formData.isActive ? 1 : 0,
  };

  if (props.isEdit) {
    await productItemStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "product-item-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await productItemStore.postCreate(payload);

    const propertyModal = {
      id: "product-item-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};
</script>
