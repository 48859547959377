<template>
  <BaseModal title="Purchase Order Item Export To Excel">
    <form @submit.prevent="save">
      <div class="grid grid-cols-2 gap-5">
        <BaseDate
          :validate="validate"
          name="startDate"
          label="Start Date"
        ></BaseDate>

        <BaseDate
          :validate="validate"
          name="endDate"
          label="End Date"
        ></BaseDate>
      </div>

      <button type="submit" class="btn btn-block w-full btn-primary mt-5">
        Export
      </button>
    </form>
  </BaseModal>
</template>
<script setup>
import BaseModal from "@/components/base/modal/Main.vue";
import {
  required,
  minLength,
  requiredIf,
  numeric,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { reactive, toRefs } from "vue";
import { usePurchaseOrderItemStore } from "@/stores/modules/purchase-order-item";
import { useModalStore } from "@/stores/modal";

const purchaseOrderItemStore = usePurchaseOrderItemStore();
const modalStore = useModalStore();

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
});

const formData = reactive({
  startDate: "",
  endDate: "",
});

const rules = {
  startDate: {
    required,
  },
  endDate: {
    required,
  },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  purchaseOrderItemStore.setQueryExport("start_date", formData.startDate);
  purchaseOrderItemStore.setQueryExport("end_date", formData.endDate);

  await purchaseOrderItemStore.exportExcel({
    start_date: formData.startDate,
    end_date: formData.endDate,
  });

  const propertyModal = {
    id: "purchase-order-item-export-excel",
    show: false,
  };

  modalStore.setModal(propertyModal);
};
</script>
