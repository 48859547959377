import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import globalComponents from "./global-components";
import baseComponents from "./components/base";
import utils from "./utils";
import "./assets/css/app.css";
import * as Sentry from "@sentry/vue";

// Notivue
import { notivue } from "notivue";
import "notivue/notifications.css"; // Only needed if using built-in notifications
import "notivue/animations.css"; // Only needed if using built-in animations

// import the package
import VueAwesomePaginate from "vue-awesome-paginate";

// Vue Select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

const app = createApp(App)
  .use(router)
  .use(createPinia())
  .use(VueAwesomePaginate)
  .component("v-select", vSelect)
  .use(notivue, {
    position: "top-right",
    limit: 4,
    enqueue: true,
  });

// Sentry
Sentry.init({
  app,
  dsn: "https://2316a53f6521e846077de7cde4a8591c@o1167788.ingest.sentry.io/4505941899870208",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

globalComponents(app);
baseComponents(app);
utils(app);

app.mount("#app");
