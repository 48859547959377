import Api from "@/utils/api.js";

class purchaseOrderServices {
  async get(params) {
    const res = await Api.doGet(`purchase-order`, {
      params,
    });
    console.log("SERVICES PRODUCT ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`purchase-order/${id}`);
    console.log("SERVICES PRODUCT BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`purchase-order`, params);
    console.log("SERVICES PRODUCT CREATE", res);
    return res;
  }

  async postWithSessionCreate(params) {
    const res = await Api.doPost(`purchase-order/store-with-session`, params);
    console.log("SERVICES PRODUCT CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`purchase-order/${id}`, params);
    console.log("SERVICES PRODUCT UPDATE", res);
    return res;
  }

  async putWithSessionUpdate(id, params) {
    const res = await Api.doPut(
      `purchase-order/${id}/update-with-session`,
      params
    );
    console.log("SERVICES PRODUCT UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`purchase-order/${id}`);
    console.log("SERVICES PRODUCT DELETE", res);
    return res;
  }

  async exportExcel(params) {
    const res = await Api.doPostBlob(`purchase-order/export`, params);
    console.log("SERVICES PRODUCT EXPORT", res);
    return res;
  }

  async invoice(id) {
    const res = await Api.doGet(`invoice/uuid`, {
      params: {
        uuid: id,
      },
    });
    console.log("SERVICES PRODUCT INVOICE", res);
    return res;
  }
}

export default new purchaseOrderServices();
