<script setup>
import { ref, onMounted, watch } from "vue";
import { usePush } from "notivue";
import { useNotificationStore } from "@/stores/notification";

const push = usePush();
const notificationStore = useNotificationStore();

watch(notificationStore.notification, (val) => {
  if (val.type !== "") {
    push[val.type]({
      title: val.title || "",
      message: val.message || "",
    });
  }
});
</script>
<template></template>
