import { defineStore } from "pinia";

export const useSimpleMenuStore = defineStore("simpleMenu", {
  state: () => ({
    menu: [
      {
        icon: "HomeIcon",
        pageName: "simple-menu-home",
        title: "Home",
      },
      // {
      //   icon: "HomeIcon",
      //   pageName: "simple-menu-session",
      //   title: "Session",
      // },
      {
        icon: "HomeIcon",
        pageName: "simple-menu-purchase-order",
        title: "Purchase Order",
      },
      // {
      //   icon: "HomeIcon",
      //   pageName: "simple-menu-page-1",
      //   title: "Page 1",
      // },
      // {
      //   icon: "HomeIcon",
      //   pageName: "simple-menu-page-2",
      //   title: "Page 2",
      // },
      {
        icon: "HomeIcon",
        pageName: "simple-menu-master",
        title: "Master",
        subMenu: [
          {
            icon: "HomeIcon",
            pageName: "simple-menu-branch",
            title: "Branch",
          },
          {
            icon: "HomeIcon",
            pageName: "simple-menu-customer",
            title: "Customer",
          },
          {
            icon: "HomeIcon",
            pageName: "simple-menu-employee",
            title: "Employee",
          },
          {
            icon: "HomeIcon",
            pageName: "simple-menu-product",
            title: "Product",
          },
          {
            icon: "HomeIcon",
            pageName: "simple-menu-promo",
            title: "Promo",
          },
          // {
          //   icon: "HomeIcon",
          //   pageName: "simple-menu-role",
          //   title: "Role",
          // },
          // {
          //   icon: "HomeIcon",
          //   pageName: "simple-menu-user",
          //   title: "User",
          // },
          {
            icon: "HomeIcon",
            pageName: "simple-menu-category",
            title: "Category",
          },
        ],
      },
    ],
  }),
});
