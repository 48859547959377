import Api from "@/utils/api.js";

class categoryServices {
  async get(params) {
    const res = await Api.doGet(`category`, {
      params,
    });
    console.log("SERVICES CATEGORY ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`category/${id}`);
    console.log("SERVICES CATEGORY BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPost(`category`, params);
    console.log("SERVICES CATEGORY CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPut(`category/${id}`, params);
    console.log("SERVICES CATEGORY UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`category/${id}`);
    console.log("SERVICES CATEGORY DELETE", res);
    return res;
  }
}

export default new categoryServices();
