<template>
  <form class="flex flex-col gap-3 validate-form" @submit.prevent="save">
    <BaseSelectAuto
      :validate="validate"
      name="uuid"
      label="No PO"
      :disabled="disabledAll"
      :options="uuidOptions"
      @input="handleUuidInput($event)"
      :reduce="(item) => item"
    ></BaseSelectAuto>

    <BaseSelectAuto
      :validate="validate"
      name="customer"
      label="Customer"
      :options="customerOptions"
      disabled="true"
    ></BaseSelectAuto>

    <BaseSelectAuto
      :validate="validate"
      name="productItem"
      label="Product Item"
      :disabled="disabledAll"
      :options="productItemOptions"
    ></BaseSelectAuto>

    <!-- <BaseCheckbox
      :validate="validate"
      name="isActive"
      label="Is Active"
      :disabled="disabledAll"
    ></BaseCheckbox> -->

    <BaseText
      :validate="validate"
      name="qty"
      label="Quantity"
      :disabled="disabledAll"
    ></BaseText>

    <div class="flex justify-end" v-if="!isDetail">
      <button
        type="button"
        @click="onClickCancel"
        class="w-20 mr-1 btn btn-outline-secondary"
      >
        Cancel
      </button>
      <button type="submit" class="w-20 btn btn-primary btn-block">
        {{ !isEdit ? "Save" : "Update" }}
      </button>
    </div>
  </form>
</template>
<script setup>
import { useModalStore } from "@/stores/modal";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  requiredIf,
  numeric,
} from "@vuelidate/validators";
import { onMounted, reactive, toRefs, ref, computed, watch } from "vue";
import { usePurchaseOrderItemStore } from "@/stores/modules/purchase-order-item";
import { useCategoryStore } from "@/stores/modules/category";
import { useProductItemStore } from "@/stores/modules/product-item";
import { useCustomerStore } from "@/stores/modules/customer";
import { usePromoStore } from "@/stores/modules/promo";
import { useEmployeeStore } from "@/stores/modules/employee";
import { showFailedPopup } from "@/utils/popup";
import { helper } from "@/utils/helper.js";
import _, { debounce } from "lodash";

const props = defineProps({
  data: {
    type: [String, Number, Object, Array, Boolean],
    default: "",
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
  isAdd: {
    type: Boolean,
    default: false,
  },
});

const modalStore = useModalStore();
const purchaseOrderItemStore = usePurchaseOrderItemStore();
const productItemStore = useProductItemStore();
const customerStore = useCustomerStore();

const onClickCancel = () => {
  const propertyModal = {
    id: `purchase-item-${props.isEdit ? "edit" : "add"}`,
    show: false,
    size: "lg",
    data: "",
  };

  modalStore.setModal(propertyModal);
};

const disabledAll = props.isDetail ? true : false;

const formData = reactive({
  uuid: props.data?.uuid || "",
  customer: props.data.customer_id || "",
  isActive: props.data?.is_active ? true : props.isAdd ? true : false,
  productItem: props.data?.product_item_id || "",
  qty: props.data?.qty || "",
});

const rules = {
  customer: {
    required,
  },
  productItem: {
    required,
  },
  qty: {
    required,
    numeric,
  },
  uuid: {
    required,
  },
  // isActive: {
  //   required,
  // },
};

const validate = useVuelidate(rules, toRefs(formData));

const save = async () => {
  validate.value.$touch();

  if (validate.value.$error) {
    return;
  }

  const payload = {
    uuid: formData.uuid.value.uuid,
    customer_id: formData.customer,
    product_item_id: formData.productItem,
    // is_active: formData.isActive ? 1 : 0,
    qty: formData.qty,
  };

  if (props.isEdit) {
    await purchaseOrderItemStore.putUpdate(props.data.id, payload);
    const propertyModal = {
      id: "purchase-order-item-edit",
      show: false,
    };

    modalStore.setModal(propertyModal);
  } else {
    await purchaseOrderItemStore.postCreate(payload);

    const propertyModal = {
      id: "purchase-order-item-add",
      show: false,
    };

    modalStore.setModal(propertyModal);
  }
};

// const categoryStore = useCategoryStore();

onMounted(async () => {
  // await categoryStore.getAll();
  await productItemStore.getAll();
  await customerStore.getAll();

  if (props.isEdit) {
  }
});

const productItemOptions = computed(() => {
  return productItemStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});

const customerOptions = computed(() => {
  return customerStore.lists?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });
});

const handleUuidInput = debounce(async (value) => {
  fetchUuid(value);
}, 1000);

const fetchUuid = async (value) => {
  const param = {
    uuid: value.target.value,
  };

  await purchaseOrderItemStore.getUuid(param);
};

// formDAta uuid watch
watch(
  () => formData.uuid,
  (value) => {
    if (value) {
      formData.customer = value.value?.customer_id;
    }
  }
);

const uuidOptions = computed(() => {
  return purchaseOrderItemStore.uuidLists?.data?.map((item) => {
    return {
      label: item.uuid,
      value: item,
    };
  });
});
</script>
