import Api from "@/utils/api.js";

class branchServices {
  async get(params) {
    const res = await Api.doGet(`branch`, {
      params,
    });
    console.log("SERVICES BRANCH ALL}", res);
    return res;
  }

  async getById(id) {
    const res = await Api.doGet(`branch/${id}`);
    console.log("SERVICES BRANCH BY ID", res);
    return res;
  }

  async postCreate(params) {
    const res = await Api.doPostMultipart(`branch`, params);
    console.log("SERVICES BRANCH CREATE", res);
    return res;
  }

  async putUpdate(id, params) {
    const res = await Api.doPostMultipart(`branch/${id}`, params);
    console.log("SERVICES BRANCH UPDATE", res);
    return res;
  }

  async delete(id) {
    const res = await Api.doDelete(`branch/${id}`);
    console.log("SERVICES BRANCH DELETE", res);
    return res;
  }
}

export default new branchServices();
