import Swal from "sweetalert2";
import { useNotificationStore } from "../stores/notification";

export const showSuccessPopup = async (titleMessage, textMessage) => {
  const notificationStore = useNotificationStore();
  notificationStore.setNotification({
    type: "success",
    message: textMessage,
  });
};

export const showFailedPopup = (titleMessage, textMessage) => {
  const notificationStore = useNotificationStore();
  notificationStore.setNotification({
    type: "error",
    message: textMessage,
  });
};

export const showConfirmPopup = (titleMessage, textMessage) => {
  return Swal.fire({
    title: titleMessage,
    text: textMessage,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  });
};
